/**
 * NavToggle produces a nav container with a button
 * to toggle the display of the links.
 * Supported props: toplinks, sitename, theme, ctx, currentpath, localizerfn
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import logo from '../images/logo.png';
import LocaleSwitcher from './LocaleSwitcher';
import { css } from '../util/pagetools';

export default function Nav(props) {
    const navStyle = css('container-fluid', props.theme);
    const localized = props.localizerfn;
    const localeSelector = (props.ctx ? <LocaleSwitcher ctx={props.ctx} currentpath={props.currentpath} rootStyle="col-xs-8" /> :
        <div></div>);

    const [open, setOpen] = useState(false);
    const navLinks = <LinkMenu links={props.toplinks} listStyle={`nav-list`} itemStyle={`nav-item`} localizerfn={localized} />;

    const navLinkContainerMobile = css('row vis-mobile navbar-links', (open ? 'open' : null))
    function openMenu(e) {
        const isExpanded = !open;
        let targetElem = e.target;
        if (targetElem.tagName !== 'BUTTON') {
            targetElem = targetElem.closest('BUTTON');
        }
        if (isExpanded) targetElem.classList.add('open');
        else targetElem.classList.remove('open');
        setOpen(!open);
    }
    
    return (
        <nav id="navbar" className={navStyle}>
            <div className="desktop-nav vis-desktop">
                <div id="navbar-narrow" className="row navbar-links">{navLinks}</div>
            </div>
            
            <div id="logo">
                <Link to={localized('/')} title={props.sitename}>
                    <img src={logo} alt={props.siteurl} className="img-responsive" />
                </Link>
                <div className="mobile-nav vis-mobile">
                    <button className="navbar-btn" onClick={openMenu}>
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar top"></span>
                        <span className="icon-bar middle"></span>
                        <span className="icon-bar bottom"></span>
                    </button>
                    
                </div>
            </div>
            <div className={navLinkContainerMobile}>{open && navLinks}</div>
        </nav>
    );
};
