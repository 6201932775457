/**
 * Render repeating sections with a picture for each.
 * Supported props: sections images theme
 */
import React from 'react';
import { Link } from 'gatsby';

export default function MainSection(props) {
  if (!Array.isArray(props.sections)) return <div>No sections</div>;

  const rootAttribs = {id: 'home'};
  if (props.theme) rootAttribs.className = props.theme;
  const pictures = props.images || Array(props.sections.length);
  const pictureCount = pictures.length;

  return (
    <div {...rootAttribs}>
      {props.sections.map((sec, index) => (
        <div id={sec.id} key={`d-${index}`}>
          {pictures[index%pictureCount]}
          <div className="main-section-text">
            <h2 className="text-center">{sec.title}</h2>
            <p className="pl-20">{sec.text[0]}</p>
            {sec.pagelink &&
              <div className="flx flx-ctr">
                <Link to={sec.pagelink} className="std-btn btn-white-on-pary">{sec.label || 'a page'}</Link>
              </div>
            }
          </div>
        </div>
      ))}
    </div>
  );
};
