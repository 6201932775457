/**
 * Render a head tag for a page.
 * Supported props: lang sitename siteurl meta
 */
import React from 'react';
import { Helmet } from 'react-helmet';

export default function PageHead(props) {
  return (
    <Helmet htmlAttributes={{lang: props.lang}}>
      <meta charSet="utf-8" />
      <title>{props.sitename}</title>
      <link rel="canonical" href={props.siteurl} />
      <meta name="description" content={props.metaDescription}></meta>
    </Helmet>
  );
};
