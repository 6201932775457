/**
 * PageLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: sitename locfn header theme links footer
 */

import React from 'react';
import Nav from './Nav';
import Footer from './FooterSimple';
import CookieBanner from './CookieBanner';

export default function PageLayout(props) {
  const mainstyle = (props.theme ? {className: props.theme} : {});

  return (
    <div id="page">
      <div id="layout-root">
        {props.header && <header>{props.header}</header>}
        {!props.header &&
        <header>
          <Nav toplinks={props.links} sitename={props.sitename} localizerfn={props.locfn} />
        </header>
        }
        <main {...mainstyle}>{props.children}</main>
        <Footer src={props.footer} localizerfn={props.locfn} />
      </div>
      <CookieBanner />
    </div>
  );
};
