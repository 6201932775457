/* Home page */

import React from 'react';
import { Link , graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/PageLayout';
import PageHead from '../components/PageHead';
import MainSection from '../components/MainSection';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;
  const imgs = [
    <div className="-fluid" key="ctx1"><StaticImage src='../images/ctximg_1.jpg' alt="" className="mx-auto" /></div>,
    <div key="ctx2"><StaticImage src='../images/ctximg_2.jpg' alt="" className="mx-auto" /></div>,
    <div id="bottom-img" key="ctx3"><StaticImage src='../images/ctximg_3.jpg' alt="" className="mx-auto" /></div>
  ];

  const toplinks = setLinks(sitedata.pagelinks);
  const sitename = sitedata.sitename;
  const siteurl = sitedata.siteurl;
  const footer = { ...sitedata.footer, toplinks, sitename, siteurl };

  return (
    <Layout sitename={sitename} links={toplinks} footer={footer} locfn={localizer(ctx)}>
      <PageHead lang={ctx.locale} sitename={sitename} siteurl={siteurl} meta={sitedata.metaDescription} />
      <MainSection sections={sitedata.sections} images={imgs} />
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      sitename
      siteurl
      metaDescription
      banner {
        theme
        btntheme
      }
      navbar {
        theme
      }
      pagelinks {
        title
        linkto
      }
      sections {
        id
        title
        header
        theme
        linkto
        pagelink
        label
        text
      }
      footer {
        theme
        text
        links {
          label
          to
          newtab
        }
      }
      errorpage {
        header
        subheader
      }
    }
  }`;
